<template>
  <div class="yuebaoRecord">
    <!-- 导航栏 -->
    <van-nav-bar
      title="Lịch sử"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >Lịch sử</span
        >
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  name: 'YuebaoRecord',
  components: {},
  data() {
    return {
      type: null,
      pages: 1,
      pagesize: 15,
    }
  },
  mounted() {},
  created() {},
  methods: {},
}
</script>

<style lang="less" scoped>
.yuebaoRecord {
  /deep/ .van-nav-bar__content {
    // background-color: #fc6925;
    /deep/ .van-nav-bar__title,
    .van-nav-bar__right {
      // color: #fff;
    }

    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }
}
</style>
